import React, { useMemo, useContext } from 'react';
import { WalkDataContext } from './hooks/walkDataContext';

const Playlist = ({
  handleTrack
}) => {
  const { currentTrack, tracks, storyMetas, language, contentState } = useContext(WalkDataContext);

  // Memoize the rendered tracks to avoid unnecessary re-renders
  const renderedTracks = useMemo(() => {
    return tracks
      .filter(track => {
        // Finde den entsprechenden Eintrag im contentState  
        const contentStateEntry = contentState.find(item => item.slug === track.slug && !track.metadata.trackMeta_radio?.[1]);
        // Rendere nur Tracks, deren state 'active' ist
        return contentStateEntry && contentStateEntry.state === 'active';
      })
      .map((track) => {
        return (
          <button
            key={track.slug}
            onClick={() => handleTrack(track.slug)}
            className={`list-group-item list-group-item-action d-flex align-items-center ${track.slug === currentTrack.slug ? 'active' : ''}`}
          >
            <div className="playlist-thumbnail-container">
              <div className="playlist-thumbnail">
                {(track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) ||
                (storyMetas.storyMeta_cover && storyMetas.storyMeta_cover[language]) ? (
                  <img 
                    className="audio-image-small" 
                    src={
                      (track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) || 
                      (storyMetas.storyMeta_cover && storyMetas.storyMeta_cover[language]) || 
                      "/assets/illustrations/placeholder-cover.jpg"
                    } 
                    alt="audio avatar" 
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = "/assets/illustrations/placeholder-cover.jpg";
                    }}
                  />
                ) : (
                  <img 
                    className="audio-image-small" 
                    src="/assets/illustrations/placeholder-cover.jpg" 
                    alt="Cover" 
                  />
                )}
              </div>
            </div>
            <div className="flex-grow-1">
              {track.metadata.trackMeta_name ? track.metadata.trackMeta_name[language] : 'Unbenannter Track'}
            </div>
          </button>
        );
      });
  }, [tracks, currentTrack.slug, language, contentState, handleTrack]);

  return (
    <>
      <div className='title-page'><h2>Stationen</h2></div>
      <div className="playlist-wrapper list-group">
        {renderedTracks}
      </div>
    </>
  );
};

export default Playlist;