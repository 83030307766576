import React, { useContext } from 'react';
import OutdoorMap from './OutdoorMap';
import IndoorMap from './IndoorMap';
import { WalkDataContext } from '../hooks/walkDataContext';

const MapPopup = ({ handleTrack, currentTrackSlug, paths, }) => {
  const { language, storyMetas, tracks, contentState } = useContext(WalkDataContext);
  const mapImageUrl = storyMetas.storyMeta_mapimage ? storyMetas.storyMeta_mapimage[1] : null; 
  
  const activeTracks = tracks.filter(track => {
          const contentStateEntry = contentState.find(item => item.slug === track.slug && !track.metadata.trackMeta_radio?.[1]);
          return contentStateEntry && contentStateEntry.state === 'active';
        })


  if (mapImageUrl){
    return(
      <IndoorMap
      tracks={activeTracks}
      handleTrack={handleTrack}
      currentTrackSlug={currentTrackSlug}
      paths={paths}
      language={language}
      metas={storyMetas}
      imageUrl={mapImageUrl}/>
    );
  } else {
    return(
      <OutdoorMap
        tracks={activeTracks}
        handleTrack={handleTrack}
        currentTrackSlug={currentTrackSlug}
        paths={paths}
        language={language}
        metas={storyMetas}
      />
    );
  }
}
export default MapPopup;
