import { useEffect, useCallback, useContext } from 'react';
import { WalkDataContext } from './hooks/walkDataContext';
import {getStoryMetadataValue} from './utility/MetadataFetcher';

const MediaSession = () => {

const { storyMetas, language, currentTrack, setIsPlaying } = useContext(WalkDataContext);
  const getMetadataValue = useCallback((source, key, defaultValue = '') => {
    return source && source[key] && source[key][language]
      ? source[key][language]
      : defaultValue;
  }, [language]);

  const updateMediaSession = useCallback(() => {
    if (!('mediaSession' in navigator) || !currentTrack) return;

    const getThumbnail = () => getMetadataValue(currentTrack.metadata, 'trackMeta_cover') || 
                               getMetadataValue(storyMetas, 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg');

    const getArtist = () => getMetadataValue(currentTrack.metadata, 'trackMeta_author') || 
                            getMetadataValue(storyMetas, 'storyMeta_author');

    navigator.mediaSession.metadata = new window.MediaMetadata({
      title: getMetadataValue(currentTrack.metadata, 'trackMeta_name'),
      artist: getArtist(),
      album: getMetadataValue(storyMetas, 'storyMeta_title'),
      artwork: [{ src: getThumbnail(), sizes: '512x512', type: 'image/png' }]
    });

    navigator.mediaSession.setActionHandler('play', () => setIsPlaying(true));
    navigator.mediaSession.setActionHandler('pause', () => setIsPlaying(false));
  }, [currentTrack, storyMetas, language, getMetadataValue, setIsPlaying]);

  useEffect(() => {
    if (storyMetas === null) return;

    const title = getStoryMetadataValue(storyMetas, language, 'storyMeta_title', 'Ortsieler');
    const favicon = getStoryMetadataValue(storyMetas, language, 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg');

    document.title = title;

    const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    if (!link.parentNode) document.head.appendChild(link);

    updateMediaSession();
  }, [storyMetas, language, currentTrack, updateMediaSession]);

  return null;
};

export default MediaSession;