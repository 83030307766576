import { useContext } from 'react';
import { WalkDataContext } from '../hooks/walkDataContext';

/**
 * Hilfsfunktion zum Konvertieren von Strings in gültiges JSON
 */
export const convertToValidJson = (str) => {
  return str ? str.replace(/'/g, '"') : '[]';
};

/**
 * Parse JSON-Daten aus String (ggf. mit einfachen Anführungszeichen)
 */
export const parseJsonContent = (contentString) => {
  try {
    const validJsonString = convertToValidJson(contentString);
    return JSON.parse(validJsonString);
  } catch (error) {
    console.error("Error parsing content:", error);
    return [];
  }
};

/**
 * Hook zum Aktivieren von Content-Elementen
 */
export const useContentActivator = () => {
  const { 
    contentState, 
    setElementState,
    setExpandedQuest,
    setExpandedPuzzle,
    quests 
  } = useContext(WalkDataContext);

  /**
   * Aktiviert eine Liste von Content-Elementen
   * @param {Array} activationItems - Array von Objekten mit {type, slug, action?}
   * @param {Function} onComplete - Callback nach Abschluss
   */
  const activateContent = (activationItems = [], onComplete = null) => {
    if (!activationItems || activationItems.length === 0) return;
    // Kopie des contentState für Updates
    const updatedContentState = [...contentState];
    
    // Zähler für asynchrone Operationen
    let pendingOperations = activationItems.length;
    
    // Alle zu aktivierenden Items durchgehen
    activationItems.forEach(item => {
      // Bestimme den Delay (Standard: 3s für 'track', 0s für andere)
      const delay = item.delay !== undefined 
        ? parseFloat(item.delay) 
        : (item.type === 'track' ? 3 : 0);
      
      // Funktion zum Aktualisieren des Zustands nach dem Delay
      const updateAfterDelay = () => {
        const index = updatedContentState.findIndex(
          stateItem => stateItem.type === item.type && stateItem.slug === item.slug
        );
        
        // Bestimme den aktuellen Zustand und Highlight-Status
        const currentState = index !== -1 ? updatedContentState[index].state : 'inactive';
        const currentHighlight = index !== -1 ? updatedContentState[index].highlight : false;
        
        // Bestimme den neuen Zustand
        const newState = item.action || (currentState === 'finished' ? 'finished' : 'active');
        const newHighlightState = currentState === 'inactive' ? true : currentHighlight;
        
        // Nur aktualisieren, wenn der state nicht "finished" ist, es sei denn action ist explizit angegeben
        if (currentState !== 'finished' || item.action) {
          setElementState(item.type, item.slug, newState, newHighlightState);
          
          // Lokale Kopie auch aktualisieren
          if (index !== -1) {
            updatedContentState[index] = {
              ...updatedContentState[index],
              state: newState,
              highlight: newHighlightState
            };
          } else {
            updatedContentState.push({
              type: item.type,
              slug: item.slug,
              state: newState,
              highlight: newHighlightState
            });
          }
        }
        
        // Überprüfen, ob alle Operationen abgeschlossen sind
        pendingOperations--;
        if (pendingOperations === 0 && onComplete) {
          onComplete();
        }
      };
      
      // Verzögerung anwenden, wenn nötig
      if (delay > 0) {
        setTimeout(updateAfterDelay, delay * 1000);
      } else {
        updateAfterDelay();
      }
    });
  };

  /**
   * Findet das nächste aktive Puzzle in der Reihenfolge
   */
  const findSmallestActivePuzzle = (quests, contentState) => {
    const sortedQuests = quests.sort((a, b) => a.position - b.position);
  
    for (const quest of sortedQuests) {
      const questState = contentState.find(item => item.type === 'quest' && item.slug === quest.slug);
      
      if (questState && questState.state === 'active') {
        const sortedPuzzles = quest.puzzles.sort((a, b) => a.position - b.position);
        
        for (const puzzle of sortedPuzzles) {
          const puzzleState = contentState.find(item => item.type === 'puzzle' && item.slug === puzzle.slug);
          if (puzzleState && puzzleState.state === 'active') {
            return {
              questSlug: quest.slug,
              puzzleSlug: puzzle.slug
            };
          }
        }
      }
    }
    
    return null;
  };

  /**
   * Aktualisiert die Anzeige auf das nächste aktive Puzzle
   */
  const updateSmallestActivePuzzle = (quests, contentState, setExpandedQuest, setExpandedPuzzle) => {
    const smallest = findSmallestActivePuzzle(quests, contentState);
    if (smallest) {
      setExpandedQuest(smallest.questSlug);
      setExpandedPuzzle(smallest.puzzleSlug);
    }
  };

  return { activateContent, parseJsonContent };
};