import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import { faMapMarkerAlt, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renderToStaticMarkup } from 'react-dom/server';
import { Container, Button, Row, Col } from 'react-bootstrap';

const OutdoorMap = ({ tracks = [], handleTrack, currentTrackSlug, paths, language, metas }) => {
  const [tileUrl, setTileUrl] = useState("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
  const mapRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);

  const currentTrack = useMemo(() => {
    return tracks.find(track => track.slug === currentTrackSlug);
  }, [tracks, currentTrackSlug]);

  const getWaypoint = useMemo(() => {
    return (track) => {
      if (!track?.metadata?.trackMeta_location) {
        return [0, 0];
      }
      const locationData = track.metadata.trackMeta_location[1];
      const location = JSON.parse(locationData);
      return [location.x, location.y];
    };
  }, []);

  // Funktion, um einen Track mit Standortdaten zu finden
  const findTrackWithLocation = useMemo(() => {
    return () => {
      // Zuerst current track prüfen
      if (currentTrack?.metadata?.trackMeta_location?.[1]) {
        return currentTrack;
      }
      
      // Wenn kein currentTrack mit Location, dann durch tracks iterieren
      for (const track of tracks) {
        if (track?.metadata?.trackMeta_location?.[1]) {
          return track;
        }
      }
      
      // Fallback: Kein Track mit Location gefunden
      return null;
    };
  }, [currentTrack, tracks]);

  // Ermittle initiale Kartenposition und Zoom basierend auf verfügbaren Daten
  const initialMapSettings = useMemo(() => {
    const trackWithLocation = findTrackWithLocation();
    
    if (trackWithLocation) {
      // Track mit Location gefunden
      return {
        center: getWaypoint(trackWithLocation),
        zoom: trackWithLocation === currentTrack ? 16 : 14 // Moderatere Zoom-Levels
      };
    }
    
    // Fallback: Keine Location gefunden
    return { center: [0, 0], zoom: 3 };
  }, [findTrackWithLocation, getWaypoint, currentTrack]);

  useEffect(() => {
    if (!mapRef.current) return;

    if (currentTrack?.metadata?.trackMeta_location?.[1]) {
      // Wenn der aktuelle Track Standortdaten hat, fliege direkt dorthin
      const locationData = currentTrack.metadata.trackMeta_location[1];
      const { x, y } = JSON.parse(locationData);
      mapRef.current.flyTo([x, y], 16); // Moderateres Zoom-Level
    } else {
      // Wenn der aktuelle Track keine Standortdaten hat, suche nach einem anderen Track mit Standortdaten
      const trackWithLocation = findTrackWithLocation();
      if (trackWithLocation && trackWithLocation !== currentTrack) {
        const position = getWaypoint(trackWithLocation);
        mapRef.current.flyTo(position, 15); // Weiteres Zoom, wenn es ein Fallback-Track ist
      }
    }
    
    mapRef.current.closePopup();
  }, [currentTrackSlug, findTrackWithLocation, getWaypoint]);

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
        mapRef.current.flyTo([latitude, longitude], 13);
      },
      () => {
        alert("Der Standort kann nicht bestimmt werden.");
      }
    );
  };

  const createIconMarkup = (icon, color) => {
    const htmlString = renderToStaticMarkup(
      <FontAwesomeIcon icon={icon} style={{ color: color, fontSize: '1em' }} />
    );
    const svgEncoded = encodeURIComponent(htmlString);
    return `data:image/svg+xml,${svgEncoded}`;
  };

  const userIconUrl = createIconMarkup(faMapMarkerAlt, "#0008a3");
  const userIcon = new L.Icon({
    iconUrl: userIconUrl,
    iconSize: [31, 41],
    iconAnchor: [15.5, 41],
    popupAnchor: [0, -41],
    className: 'custom-icon',
  });

  const getIcon = (isSelected, trackCoverUrl, storyCoverUrl) => new L.Icon({
    iconUrl: trackCoverUrl || storyCoverUrl || "/assets/illustrations/placeholder-cover.jpg",
    iconSize: isSelected ? [50, 50] : [30, 30],
    iconAnchor: isSelected ? [25, 25] : [15, 15],
    className: isSelected ? 'custom-icon selected-icon' : 'custom-icon',
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className='title-page'><h2>Übersichtskarte</h2></div>
        </Col>
      </Row>
      <Row className="map-container">
        <Col>
          {tracks.length > 0 ? (
            <MapContainer
              center={initialMapSettings.center}
              zoom={initialMapSettings.zoom}
              ref={setMap => mapRef.current = setMap}
              className="h-100"
            >
              <TileLayer
                url={tileUrl}
                attribution='Map data &copy; contributors of <a href="https://www.openstreetmap.org/">OpenStreetMap</a>'
              />
              {tracks.map((track) => (
                <Marker
                  key={track.slug}
                  position={getWaypoint(track, 'point')}
                  icon={getIcon(
                    track.slug === currentTrackSlug,
                    track.metadata?.trackMeta_cover ? track.metadata.trackMeta_cover[language] : null,
                    metas.storyMeta_cover ? metas.storyMeta_cover[language] : null
                  )}
                  zIndexOffset={track.slug === currentTrackSlug ? 1000 : 500}
                  eventHandlers={{
                    click: () => {
                      handleTrack(track.slug);
                      if (mapRef.current) {
                        // Moderateres Zoom-Level (16) beim Klicken auf Icons
                        mapRef.current.flyTo(getWaypoint(track, 'click'), 16);
                      }
                    },
                  }}
                >
                  <Popup>
                    {track.metadata?.trackMeta_name ? track.metadata.trackMeta_name[language] : 'Unbenannter Track'}
                  </Popup>
                </Marker>
              ))}

              {paths && paths.map((path, index) => (
                <Polyline
                  key={index}
                  positions={path}
                  color="blue"
                  dashArray="5, 10"
                  weight={5}
                />
              ))}

              {userLocation && <Marker position={userLocation} icon={userIcon}>
                <Popup>Mein Standort</Popup>
              </Marker>}
              <Button
                className="btn btn-primary btn-locate"
                onClick={getUserLocation}
              >
                <FontAwesomeIcon icon={faLocationArrow} />
              </Button>
            </MapContainer>
          ) : (
            <p>Keine Tracks verfügbar</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OutdoorMap;