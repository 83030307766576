import React from 'react';
import AdminNavigation from './AdminNavigation';

const AdminPanel = ({ children }) => {
  return (
    <div className="admin-panel">
      <AdminNavigation />
      <div className="admin-content">
        {children}
      </div>
    </div>
  );
};

export default AdminPanel;