import React, { useState, useContext } from 'react';
import { AuthContext } from '../components/auth/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { useNotification } from '../components/hooks/useNotifications';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, { username, password });
            
            const token = response.data.accessToken;
            localStorage.setItem('token', token);
            
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.userId;
            localStorage.setItem('userId', userId);
            
            // Aktualisieren des AuthContext-Zustands
            login(
                { 
                    username: username, 
                    userid: userId, 
                    userrole: decodedToken.role
                }, 
                decodedToken.exp
            );
            
            // Weiterleitung basierend auf Benutzerrolle
            if (decodedToken.role === '1' || decodedToken.role === 1) {
                navigate('/admin');
            } else {
                navigate('/');
            }
        } catch (error) {
            showNotification('Einloggen nicht möglich.', 'danger');
            setPassword('');
            setUsername('');
            console.error('Fehler beim Login:', error);
        }
    };

    return (
        <div className='main-container'>
            <Card>
                <form onSubmit={handleSubmit}>
                    <h2>Login</h2>
                    <input
                        className="mt1"
                        name="username"
                        type="text"
                        placeholder="Benutzername"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="username"
                    /> <br/>
                    <input
                        className="mt1"
                        name="password"
                        type="password"
                        placeholder="Passwort"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                    /><br/>
                    <Button className="mt1" type="submit">Einloggen</Button>
                </form>
            </Card>
        </div>
    );
}

export default Login;