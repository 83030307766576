import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import AdminPanel from './AdminPanel';
import AdminRoutes from '../../routes/adminRoutes';

const AdminRoute = () => {
  // Alle Hooks am Anfang der Komponente aufrufen
  const { isAdmin, isAuthenticated, isLoading, user } = useAuth();
  
  // Zeige Ladeindikator während Auth-Status geladen wird
  if (isLoading) {
    return <div>Authentifizierung wird überprüft...</div>;
  }
  
  // Umleitung zur Login-Seite, wenn nicht angemeldet oder kein Admin
  if (!isAuthenticated || !isAdmin) {
    //console.log('Kein Zugriff auf Admin-Bereich:', { isAuthenticated, isAdmin });
    return <Navigate to="/login" replace />;
  }
  
  //console.log('Admin-Bereich wird geladen, Benutzer ist berechtigt');
  
  // Render AdminPanel mit AdminRoutes
  return (
    <Suspense fallback={<div>Admin-Bereich wird geladen...</div>}>
      <AdminPanel>
        <AdminRoutes />
      </AdminPanel>
    </Suspense>
  );
};

export default AdminRoute;