import React from 'react';
import { useDevice } from '../hooks/deviceContext';
import '../../styles/desktopStyles.css';

// Komponente fügt die Klasse hinzu, die im CSS gezielt wird
export const ConditionalDesktopStyles = () => {
  const { isMobile } = useDevice();
  //const isMobile = true;
  // Füge die Klasse zum Body nur hinzu, wenn es kein Mobilgerät ist
  React.useEffect(() => {
    if (!isMobile) {
      document.body.classList.add('desktop-view');
    } else {
      document.body.classList.remove('desktop-view');
    }
    
    return () => {
      document.body.classList.remove('desktop-view');
    };
  }, [isMobile]);
  
  return null;
};

export default ConditionalDesktopStyles;