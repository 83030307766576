import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

const AdminNavigation = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar bg="light" expand="lg" expanded={expanded}>
      <Navbar.Brand as={Link} to="/admin">AdminPanel</Navbar.Brand>
      <Navbar.Toggle aria-controls="admin-navbar-nav" onClick={() => setExpanded(expanded => !expanded)} />
      <Navbar.Collapse id="admin-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown title="new Stuff" id="admin-nav-dropdown">
            <NavDropdown.Item as={Link} to="/admin/languages" onClick={() => setExpanded(false)}>Sprachen</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/deletionQueue" onClick={() => setExpanded(false)}>Dateien zum löschen</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/contents" onClick={() => setExpanded(false)}>Contents</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/cities" onClick={() => setExpanded(false)}>Städte</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/categories" onClick={() => setExpanded(false)}>Kategorien</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/story-metas" onClick={() => setExpanded(false)}>Story Metas</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/group-metas" onClick={() => setExpanded(false)}>Gruppen Metas</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/stories" onClick={() => setExpanded(false)}>Stories</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/story-contents" onClick={() => setExpanded(false)}>Story Contents DEV</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/track-metas/" onClick={() => setExpanded(false)}>Track Metas</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/track-contents" onClick={() => setExpanded(false)}>Track Contents DEV</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/backup/db" onClick={() => setExpanded(false)}>Backup DB</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/quest-metas/" onClick={() => setExpanded(false)}>Quest Metas</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/quest-contents" onClick={() => setExpanded(false)}>Quest Contents</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/puzzle-metas" onClick={() => setExpanded(false)}>Puzzle Metas</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/puzzle-contents" onClick={() => setExpanded(false)}>Puzzle Contents</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="User Manager" id="admin-nav-dropdown">
            <NavDropdown.Item as={Link} to="/admin/userManager/userDetails/" onClick={() => setExpanded(false)}>Nutzer Liste</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/admin/userManager/addUser" onClick={() => setExpanded(false)}>Nutzer hinzufügen</NavDropdown.Item>

          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AdminNavigation;