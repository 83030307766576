import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from './ProgressBar';
import { Container } from 'react-bootstrap';
import {getTrackMetadataValue} from './utility/MetadataFetcher';
import { WalkDataContext } from './hooks/walkDataContext';


const PlayerDyn = React.memo(({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  isPlaying,
  togglePlayPause,
  timeProgress,
  resetPlayer,
}) => {
  const { currentTrack, storyMetas, language, storyFeatures, setActiveTab, activeTab } = useContext(WalkDataContext);
  useEffect(() => {
    const audio = audioRef.current;
    
    const handleEnded = () => {
      setTimeProgress(0);
      resetPlayer();
    };

    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audioRef, setTimeProgress, resetPlayer]);

  const skipForward = () => {
    audioRef.current.currentTime += 15;
  };
  const skipBackward = () => {
    audioRef.current.currentTime -= 15;
  };

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + '...';
    }
    return title;
  };

  const trackTitle = getTrackMetadataValue(currentTrack, storyMetas, language, 'trackMeta_name', 'storyMeta_name', ' ');
  const trackCover = getTrackMetadataValue(currentTrack, storyMetas, language, 'trackMeta_cover', 'storyMeta_cover', '/assets/illustrations/placeholder-cover.jpg');

  return  activeTab==='track-details' ? (
    <Container className="">
      <div className="controls-wrapper">
        <div className='mt-150 w-90'>
          <ProgressBar
            audioRef={audioRef}
            progressBarRef={progressBarRef}
            duration={duration}
            timeProgress={timeProgress}
            setTimeProgress={setTimeProgress}
          />
        </div>
        <div className="controls-pbig mt-1">
          <button onClick={skipBackward}>
            <FontAwesomeIcon icon={faBackward} />
          </button>
          <button onClick={togglePlayPause}>
            {isPlaying ? <FontAwesomeIcon icon={faPause} size="2x" /> : <FontAwesomeIcon icon={faPlay} size="2x" />}
          </button>
          <button onClick={skipForward}>
            <FontAwesomeIcon icon={faForward} />
          </button>
        </div>
      </div>
    </Container>
  ) : (
    <Container className='ml-150'>
      <div className="audio-player-container-small">
        <div className="player-header">
          <div className="track-thumbnail">
          <img className="audio-image-small" onClick={() => {
                                                if (storyFeatures.includes('track-details') || storyFeatures.includes('track-details-no-icon')) {
                                                  setActiveTab('track-details');}
                                                }} src={trackCover} alt="Cover" />
          </div>
          <div className="controls w-3">
            <button onClick={togglePlayPause}>
              {isPlaying ? <FontAwesomeIcon icon={faPause} size="2x" /> : <FontAwesomeIcon icon={faPlay} size="2x" />}
            </button>
          </div>
          <div className="track-info">
            <div className="track-title-small">{truncateTitle(trackTitle, 30)}</div>
            <div className="progress-small">
              <ProgressBar
                audioRef={audioRef}
                progressBarRef={progressBarRef}
                duration={duration}
                timeProgress={timeProgress}
                setTimeProgress={setTimeProgress}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}, (prevProps, nextProps) => {
  return prevProps.size === nextProps.size &&
         prevProps.currentTrack === nextProps.currentTrack &&
         prevProps.metas === nextProps.metas &&
         prevProps.language === nextProps.language &&
         prevProps.isPlaying === nextProps.isPlaying &&
         prevProps.timeProgress === nextProps.timeProgress;
});

export default PlayerDyn;