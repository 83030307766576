import React, { useState, useContext } from 'react';
import { Card, Image, Badge, Modal, Button } from 'react-bootstrap';
import { WalkDataContext } from '../hooks/walkDataContext';
import PuzzleInputText from './PuzzleInputText';
import PuzzleInputMultipleChoice from './PuzzleInputMultipleChoice';
import PuzzleInputQr from './PuzzleInputQr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight, faCheck, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FindNextPuzzleButton } from '../utility/NavigatePuzzles';
import { useContentActivator, parseJsonContent } from '../utility/ContentActivator';


function Puzzle({ puzzle, language }) {
  const { expandedPuzzle, setExpandedPuzzle, setExpandedQuest, getElementState, setElementState, setCurrentTrack, contentState, quests } = useContext(WalkDataContext);
  const { activateContent } = useContentActivator();
  const puzzleTitel = puzzle.metadata.puzzleMeta_title?.[language] ?? null;
  const puzzleDescription = puzzle.metadata.puzzleMeta_description?.[language] ?? null;
  const puzzleIllustration = puzzle.metadata.puzzleMeta_illustration?.[language] ?? null;
  const puzzleIAudio = puzzle.metadata.puzzleMeta_audio?.[language] ?? null;
  const puzzleType = puzzle.metadata.puzzleMeta_type?.['1'] ?? null;
  const puzzleContent = puzzle.metadata.puzzleMeta_content?.[language] ?? null;  // Auswahlmöglichkeiten bei Multiple Choice
  const puzzleHint1 = puzzle.metadata.puzzleMeta_hint1?.[language] ?? null;
  const puzzleSovedText = puzzle.metadata.puzzleMeta_finishedtext?.[language] ?? null;
  //const puzzleHint2 = puzzle.metadata.puzzleMeta_hint2?.[language] ?? null;
  //const puzzleHint3 = puzzle.metadata.puzzleMeta_hint3?.[language] ?? null;
  const puzzleSlug = puzzle.slug ?? null;
  const puzzleState = getElementState('puzzle', puzzleSlug);
  const badgeVariant = puzzleState === 'active' ? 'success' : 'secondary';
  


  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (e) => {
    e.stopPropagation();
    setShowImageModal(true);
  };

  // Audiotrack-Setup bleibt unverändert
  const setExternalAudio = () => setCurrentTrack({
    'position': 99,
    'slug': 'temp', 
    'metadata': {
      'trackMeta_audiotrack': {
        '2': puzzleIAudio
      },
      'trackMeta_cover': {'2': puzzleIllustration ? puzzleIllustration : null},
      'trackMeta_name': {'2': puzzleTitel}
    }
  });

  // Parser für Solution bleibt, nutzt aber nun die zentrale Funktion
  const puzzleSolution = parseJsonContent(puzzle.metadata.puzzleMeta_solution?.[language] ?? '[]');
  
  // Parser für ActivatesContent, nutzt nun die zentrale Funktion
  const puzzleActivatesContent = parseJsonContent(puzzle.metadata.puzzleMeta_activatescontent?.['1'] ?? '[]');

  // Überprüfen der Lösung, nutzt neuen ContentActivator
  const checkSolution = (matchedSolutionIndex) => {
    if (matchedSolutionIndex !== -1) {
      setTimeout(() => {
        // Puzzle als gelöst markieren
        setElementState('puzzle', puzzleSlug, 'finished');
        
        // Inhalte aktivieren, die durch diese Lösung freigeschaltet werden
        const itemsToActivate = puzzleActivatesContent[matchedSolutionIndex] || [];
        
        // Verwende die neue ContentActivator Utility
        activateContent(itemsToActivate);
      }, 2000);
    }
  };
  
  return (
    <>
      <Card className="mb-4">
        {puzzleTitel && (
          <Card.Header
            as="h5"
            className="d-flex"
            onClick={() => setExpandedPuzzle(puzzleSlug)}
          >
            <span className='mr-1'>
              {puzzleState === 'active' && <FontAwesomeIcon icon={faHandPointRight} className="text-primary fs-4" />}
              {puzzleState === 'finished' && <FontAwesomeIcon icon={faCheck} className="text-success fs-4" />}
            </span>
            {puzzleTitel}
          </Card.Header>
      )}
      {puzzleSlug === expandedPuzzle && (
        <Card.Body>
          {puzzleIllustration && (
            <div style={{
              float: 'left',
              width: '40%',
              position: 'relative',
              paddingBottom: '30%',
              cursor: 'pointer'
            }}>
              <Image 
                src={puzzleIllustration} 
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
                onClick={handleImageClick}
              />
            </div>
          )}
            {puzzleDescription && (
              <div className="puzzle-description">
                <div dangerouslySetInnerHTML={{ __html: puzzleDescription }} />
                {puzzleIAudio && (
                  <div className="mt-2">
                    <Button onClick={() => setExternalAudio()} variant="outline-secondary" size="sm">
                      <FontAwesomeIcon icon={faPlay} />
                    </Button>
                  </div>
                )}
              </div>
            )}
          <div style={{ clear: 'both' }}>
            {puzzleState === 'active' && (
              <>
                {puzzleType === "MultipleChoice" && (
                  <PuzzleInputMultipleChoice
                    content={puzzleContent}
                    solution={puzzleSolution}
                    hint1={puzzleHint1}
                    checkSolution={checkSolution}
                  />
                )}
                {puzzleType === "QR" && (
                  <PuzzleInputQr
                    solution={puzzleSolution}
                    hint1={puzzleHint1}
                    checkSolution={checkSolution}
                    cameraActive={expandedPuzzle === puzzleSlug}
                  />
                )}
                {puzzleType !== "MultipleChoice" && puzzleType !== "QR" && (
                  <PuzzleInputText
                    solution={puzzleSolution}
                    hint1={puzzleHint1}
                    checkSolution={checkSolution}
                  />
                )}
              </>
            )}
            {
              puzzleState === 'finished' && (
                <>
                <div dangerouslySetInnerHTML={{ __html: puzzleSovedText }} />
                <FindNextPuzzleButton 
                contentState={contentState}
                  expandedPuzzle={expandedPuzzle}
                  quests={quests}
                  setExpandedPuzzle={setExpandedPuzzle}
                  setExpandedQuest={setExpandedQuest}
                />
            </>
              )
            }
          </div>
        </Card.Body>
      )}
    </Card>

    <Modal 
        show={showImageModal} 
        onHide={() => setShowImageModal(false)} 
        size="xl" 
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Image 
            src={puzzleIllustration} 
            style={{
              width: '100%',
              height: '80vh',
              objectFit: 'contain'
            }}
          />
        </Modal.Body>
      </Modal>
  </>
  );
}

export default Puzzle;
