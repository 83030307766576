import React, { createContext, useState, useEffect, useCallback, useMemo} from 'react';
import { useParams } from 'react-router-dom';
export const WalkDataContext = createContext();

export const WalkDataProvider = ({ children }) => {
  const [storyData, setStoryData] = useState(null);
  const [language, setLanguage] = useState(2);
  const [storyMetas, setStoryMetas] = useState(null);
  const [tracks, setTracks] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [playerSize, setPlayerSize] = useState('small');
  const [activeTab, setActiveTab] = useState('info');      // initial view
  const [isPlaying, setIsPlaying] = useState(null);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [validTrackSlugs, setValidTrackSlugs] = useState([]);
  const [passwordAttempted, setPasswordAttempted] = useState(false);
  const [quests, setQuests] = useState(null);
  const [contentState, setContentState ] = useState(null);
  const [expandedQuest, setExpandedQuest ] = useState(null);
  const [expandedPuzzle, setExpandedPuzzle ] = useState(null);
  const [storyFeatures, setStoryFeatures] = useState(['info', 'track-details-no-icon' ,'playlist', 'map', 'qr', 'default-player']); //default setting 
  const { trackSlug, walkSlug } = useParams();

  const [audioEffects, setAudioEffects] = useState({
    isReversed: false,
    playbackRate: 1,
    reverbWet: 0,
    reverbDecay:0.1,  //muss über 1 liegen
    isPingPongDelayEnabled:false
  });

  const handleTabChange = useCallback((newTab) => {
    setActiveTab(newTab);
    setPlayerSize(newTab === 'track-details' ? 'big' : 'small');
  }, []);

  useEffect(() => {
    handleTabChange(activeTab);
  }, [activeTab, handleTabChange]);

  useEffect(() => {
    if (storyMetas && storyMetas.storyMeta_startpage ){
      handleTabChange(storyMetas.storyMeta_startpage[1]);  // zum testen - vermutlich im endeffekt überall 'track-details'
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyMetas]);

  const validatePassword = useCallback((inputPassword, clearPassword) => {
    setPasswordAttempted(true);
    const newValidSlugs = tracks?.filter(track => track.metadata?.trackMeta_password?.['1'] === inputPassword)
      .map(track => track.slug) || [];

    if (newValidSlugs.length > 0) {
      setIsPasswordValid(true);
      clearPassword();
      setValidTrackSlugs(prevValidSlugs => [...new Set([...prevValidSlugs, ...newValidSlugs])]);
    } else {
      setIsPasswordValid(false);
    }
  }, [tracks]);

function extractStates(data) {
  const stateList = [];

  // Tracks
  if (Array.isArray(data.tracks)) {
      data.tracks.forEach(track => {
          const trackState = getState(track.metadata?.trackMeta_fromstart);
          stateList.push({ 'type': 'track', 'slug': track.slug, 'state':trackState, 'highlight': trackState==='active'});
      });
  }

  // Quests und Puzzles
  if (Array.isArray(data.quests)) {
      data.quests.forEach(quest => {
          const questState = getState(quest.metadata?.questMeta_fromstart);
          stateList.push({ 'type': 'quest', 'slug': quest.slug, 'state': questState, 'highlight': questState==='active'});

          if (Array.isArray(quest.puzzles)) {
              quest.puzzles.forEach(puzzle => {
                  const puzzleState = getState(puzzle.metadata?.puzzleMeta_fromstart);
                  stateList.push({ 'type': 'puzzle', 'slug': puzzle.slug, 'state': puzzleState, 'highlight': puzzleState==='active' });
              });
          }
      });
  }
  //console.log(stateList);
  return stateList;
}

function getState(fromStartValue) {
  if (fromStartValue === undefined || fromStartValue === null) {
      return 'inactive';
  }
  const value = parseFloat(fromStartValue?.['1']);
  return isNaN(value) || value <= 0 ? 'inactive' : 'active';
}
const getElementState = (element,slug) => {
  const stateItem = contentState?.find(item => item.slug === slug && item.type === element);
  return stateItem ? stateItem.state : 'inactive';
};

const setElementState = (element, slug, newState, newHighlight=false) => {
  setContentState(prevContentState => {
    const updatedContentState = prevContentState ? [...prevContentState] : [];
    const index = updatedContentState.findIndex(item => item.slug === slug && item.type === element);

    if (index !== -1) {
      updatedContentState[index].state = newState;
      updatedContentState[index].highlight = newHighlight;
    } else {
      //console.error('Element not found. Could not set state');
    }

    return updatedContentState;
  });
};


const updateAudioEffect = useCallback((effectName, value) => {
  setAudioEffects(prev => ({ ...prev, [effectName]: value }));
}, []);


useEffect(() => {
  if (storyData?.story?.slug) {
    const savedState = localStorage.getItem(`progress_${storyData.story.slug}`);

    setStoryMetas(storyData.story.metadata);
    const tracks = storyData.tracks;
    setTracks(tracks);
    let parsedState;
    if (savedState) {
      parsedState=JSON.parse(savedState);
      setContentState(parsedState);
    } else {
      setContentState(extractStates(storyData));
    }
    //console.log(storyData.story.metadata);
    setQuests(storyData.quests);
    let parsedFeatures;
    if (storyData.story.metadata && storyData.story.metadata.storyMeta_features){
      parsedFeatures = JSON.parse(storyData.story.metadata.storyMeta_features[1]);
      setStoryFeatures(parsedFeatures);
    }
        if (storyData?.tracks && storyData.tracks.length > 0) {
          let initialTrack = null;
          if (trackSlug) {
            const foundTrack = storyData.tracks.find(track => track.slug.toString() === trackSlug.toString());
            if (foundTrack) {
              initialTrack = foundTrack;
              setActiveTab('track-details'); // hier evlt eine Reihenfolge
              setElementState('track', trackSlug.toString(), 'active', false);
            }
          } 
        else if (parsedState && parsedState.length > 0) {

            const activeTrackContents = parsedState.filter(item => { // findet aktive Tracks und filtert Radiotracks

                if (!(item.type === 'track' && item.state === 'active')) {
                    return false;
                }
                const matchingTrack = tracks.find(track => track.slug === item.slug);
                
                if (!matchingTrack) {
                    return false;
                }
                const hasRadioMetadata = matchingTrack.metadata && 
                                      matchingTrack.metadata.trackMeta_radio && 
                                      matchingTrack.metadata.trackMeta_radio["1"];
                
                return !hasRadioMetadata;
            });

            if (activeTrackContents.length > 0) {

              const activeTrackSlugs = new Set(activeTrackContents.map(item => item.slug));
              const activeTracks = storyData.tracks.filter(track => activeTrackSlugs.has(track.slug));
              
              if (activeTracks.length > 0) {
                // Wähle den Track mit der niedrigsten Position
                initialTrack = activeTracks.sort((a, b) => a.position - b.position)[0];
              }
            }
          }
          
          // Fallback - wenn kein Track durch obige Bedingungen gesetzt wurde
          if (!initialTrack) {
            initialTrack = storyData.tracks.find(track => !track.metadata?.trackMeta_password);
          }
          // Setze den ausgewählten Track
          setCurrentTrack(initialTrack);
        }

  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [storyData]);

useEffect(() => {
  if (contentState && storyData?.story?.slug) {
    localStorage.setItem(
      `progress_${storyData.story.slug}`, 
      JSON.stringify(contentState)
    );
  }
}, [contentState, storyData]);


  const contextValue = useMemo(() => ({
    storyData, setStoryData,
    language, setLanguage,
    storyMetas, setStoryMetas,
    tracks, setTracks,
    currentTrack, setCurrentTrack,
    playerSize, setPlayerSize,
    activeTab, setActiveTab,
    isPlaying, setIsPlaying,
    password, setPassword,
    isPasswordValid, setIsPasswordValid,
    handleTabChange,
    validatePassword,
    validTrackSlugs, setValidTrackSlugs,
    passwordAttempted, setPasswordAttempted,
    quests, setQuests,
    contentState, setContentState ,
    getElementState,
    setElementState,
    expandedQuest, setExpandedQuest,
    expandedPuzzle, setExpandedPuzzle,
    audioEffects,
    updateAudioEffect,
    storyFeatures,
    extractStates
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps
   [storyData, language, storyMetas, tracks, currentTrack,
    playerSize, activeTab, isPlaying, password, isPasswordValid,
    validTrackSlugs, passwordAttempted, quests, handleTabChange, validatePassword, contentState, expandedQuest, expandedPuzzle, audioEffects,
    updateAudioEffect, storyFeatures
  ]);

  return (
    <WalkDataContext.Provider value={contextValue}>
      {children}
    </WalkDataContext.Provider>
  );
};
