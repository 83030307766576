import React, { useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const UserManager = () => {
  const { login, logout, allowCookies, cookies, setIsLoading } = useContext(AuthContext);

  // Beim ersten Laden der Anwendung
  useEffect(() => {
    //console.log("UserManager: Initialisierung...");
    
    // Cookies erlauben, wenn userId existiert
    if (localStorage.getItem('userId')) {
      allowCookies();
    }
    
    // Token aus localStorage abrufen und verarbeiten
    const token = localStorage.getItem('token');
    if (token) {
      //console.log("Token gefunden, wird verarbeitet...");
      processToken(token);
    } else {
      //console.log("Kein Token vorhanden.");
      setIsLoading(false);
    }
  }, []);
  
  // Funktion zum Verarbeiten des Tokens
  const processToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const expiryTime = decodedToken.exp;
      
      //console.log("Token dekodiert:", {
      //  exp: new Date(expiryTime * 1000).toLocaleString(),
      //  currentTime: new Date(currentTime * 1000).toLocaleString(),
      //  isValid: expiryTime >= currentTime
      //});
      
      if (decodedToken && decodedToken.exp && expiryTime >= currentTime) {
        // Token ist gültig
        const userId = decodedToken.userId;
        const username = decodedToken.username || "Benutzer";
        const role = decodedToken.role;
        
        //console.log("Gültiges Token, logge Benutzer ein:", { username, userId, role });
        
        // UserId im localStorage speichern (falls noch nicht geschehen)
        localStorage.setItem('userId', userId);
        
        // Benutzer im API registrieren
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user`, { userId })
          .catch(error => {
            console.error("API-Fehler beim Benutzerregistrieren:", error);
          });
        
        // Login im AuthContext durchführen
        login(
          { 
            username: username, 
            userid: userId, 
            userrole: role
          }, 
          expiryTime
        );
      } else {
        // Token ist abgelaufen
        console.log("Token ist abgelaufen.");
        localStorage.removeItem('token');
        logout();
      }
    } catch (error) {
      console.error("Fehler beim Dekodieren des Tokens:", error);
      localStorage.removeItem('token');
      logout();
    } finally {
      setIsLoading(false);
    }
  };

  return null;
};

export default UserManager;