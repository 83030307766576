import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [expiryTime, setExpiryTime] = useState(null);
    const [cookies, setCookies] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        // Bei Komponenten-Montage überprüfen, ob bereits ein Token im localStorage ist
        const checkExistingAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Math.floor(Date.now() / 1000);
                    
                    if (decodedToken && decodedToken.exp && decodedToken.exp > currentTime) {
                        // Token ist noch gültig
                        // console.log("AuthContext: Vorhandenes Token wird wiederhergestellt");
                        setUser({
                            username: decodedToken.username || "Benutzer", 
                            userid: decodedToken.userId,
                            userrole: decodedToken.role
                        });
                        setExpiryTime(decodedToken.exp);
                    } else {
                        // Token ist abgelaufen
                        localStorage.removeItem('token');
                    }
                } catch (error) {
                    console.error("AuthContext: Fehler beim Wiederherstellen der Authentifizierung:", error);
                    localStorage.removeItem('token');
                }
            }
            
            setIsLoading(false);
        };
        
        checkExistingAuth();
    }, []);
    
    const login = (userData, expiryTime) => {
        setUser(userData);
        setExpiryTime(expiryTime);
        setIsLoading(false);
    };
    
    const logout = () => {
        setUser(null);
        setExpiryTime(null);
        
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        setIsLoading(false);
    };
    
    const allowCookies = () => {
        setCookies(true);
    };
    
    // Admin-Status und Authentifizierung
    const isAdmin = user && (user.userrole === '1' || user.userrole === 1);
    const isAuthenticated = !!user;
    
    return (
        <AuthContext.Provider value={{ 
            user, 
            cookies, 
            expiryTime, 
            login, 
            logout, 
            allowCookies, 
            isAdmin, 
            isAuthenticated,
            isLoading,
            setIsLoading
        }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom Hook für einfacheren Zugriff auf den Auth-Kontext
export const useAuth = () => useContext(AuthContext);