import React, { useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { WalkDataContext } from '../hooks/walkDataContext';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHome, 
  faUserShield, 
  faSignOutAlt, 
  faFileAlt, 
  faShieldAlt, 
  faMusic,
  faBars // Hamburger-Menü Icon
} from '@fortawesome/free-solid-svg-icons';

import Logout from './Logout';
import Imprint from '../menuItems/Imprint';
import DataProtection from '../menuItems/DataProtection';
import More from '../menuItems/More';

function Header() {
  const { user } = useContext(AuthContext);
  const { storyData, language, setActiveTab } = useContext(WalkDataContext);
  const [showImprint, setShowImprint] = useState(false);
  const [showDSGVO, setShowDSGVO] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [menuItem, setMenuItem] = useState(null);

  const handleMenuItemClick = (item) => {
    setMenuItem(item);
    setExpanded(false);
    if (item === 'imprint') {
      setShowImprint(true);
    }
    if (item === 'datenschutz') {
      setShowDSGVO(true);
    }
  };

  const closeImprint = () => {
    setShowImprint(false);
  };

  const closeDSGVO = () => {
    setShowDSGVO(false);
  };

  const closeMenuItem = () => {
    setMenuItem(null);
  };

  return (
    <header className="header-footer header">
      <div>
        <Navbar expand="lg" expanded={expanded} className="custom-navbar" bg="light" variant="light">
          <Navbar.Brand>
          {storyData?.story?.metadata?.storyMeta_customicon?.[1] ? 
            <img 
              className='application-logo' 
              src={storyData?.story?.metadata.storyMeta_customicon[1]} 
              style={{ width: '3rem' }} 
              alt="Logo" 
            /> : 
            <img 
              className='application-logo' 
              src="/assets/logo/icon-ortspiel.png" 
              style={{ width: '3rem' }} 
              alt="Logo" 
            />
          }
            
            {user && user.userrole === 6 && <span><h3 className='inline ml-150'>Admin</h3></span>}
            {storyData && <h3 className='inline ml-150' onClick={() => setActiveTab('info')}>{storyData.story.metadata.storyMeta_title[language]}</h3>}
          </Navbar.Brand>
          <Navbar.Toggle 
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          >
            <FontAwesomeIcon 
              icon={faBars} 
              className="nav-link"
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {user && user.userrole === 1 && (
                <>
                  <Nav.Link as={Link} to="/" onClick={() => handleMenuItemClick('home')}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faHome} className="icon" />
                    </div>
                    Home
                  </Nav.Link>
                  <Nav.Link as={Link} to="/admin" onClick={() => handleMenuItemClick('admin')}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faUserShield} className="icon" />
                    </div>
                    Admin
                  </Nav.Link>
                </>
              )}
              <Nav.Link onClick={() => handleMenuItemClick('datenschutz')}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faShieldAlt} className="icon" />
                </div>
                Datenschutz
              </Nav.Link>
              <Nav.Link onClick={() => handleMenuItemClick('imprint')}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faFileAlt} className="icon" />
                </div>
                Impressum
              </Nav.Link>
              {user && user.userrole === 1 && (
                <>
                <Nav.Link onClick={() => handleMenuItemClick('logout')}>
                  <div className="icon-container">
                    <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
                  </div>
                  <Logout />
                </Nav.Link>
                <Nav.Link as={Link} to="/a/demo" onClick={() => handleMenuItemClick('demostory')}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faMusic} className="icon" />
                    </div>
                    Demo
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      {menuItem === 'more' && <More closeMenuItem={closeMenuItem} />}
      <Imprint show={showImprint} handleClose={closeImprint} />
      <DataProtection show={showDSGVO} handleClose={closeDSGVO} />
    </header>
  );
}

export default Header;