import React, { useEffect, useContext, Suspense } from 'react';
import { createBrowserRouter, RouterProvider, useLocation, Outlet, Navigate } from 'react-router-dom';
import axios from 'axios';
import { WalkDataProvider, WalkDataContext } from './components/hooks/walkDataContext';
import { NotificationProvider } from './components/hooks/useNotifications';
import { AuthProvider, useAuth } from './components/auth/AuthContext';
import { DeviceProvider } from './components/hooks/deviceContext';
import 'bootstrap/dist/css/bootstrap.min.css';

// Basis-Komponenten
import Header from './components/common/Header';
import UserManager from './components/auth/UserManager';
import HomePage from './pages/HomePage';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import AudioPlayer from './pages/AudioPlayer';

// Admin-Route-Komponente
import AdminRoute from './components/admin/AdminRoute';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';
}

// Layout-Komponente mit Context-Logic
const Layout = () => {
  const location = useLocation();
  const { setStoryData } = useContext(WalkDataContext);

  useEffect(() => {
    if (location.pathname.startsWith('/a')) {
      setStoryData(null);
    } else {
      setStoryData('');
    }
  }, [location, setStoryData]);

  return (
    <div>
      <div id="main-container">
        <div id="header">
          <Header />
        </div>
        <div id="content">
          <Suspense fallback={<div>Wird geladen...</div>}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

// Router-Konfiguration
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <DeviceProvider>
      <NotificationProvider>
        <WalkDataProvider>
          <Layout />
        </WalkDataProvider>
      </NotificationProvider>
      </DeviceProvider>
    ),
    children: [
      { index: true, element: <HomePage /> },
      { path: "a/:walkSlug/:trackSlug", element: <AudioPlayer /> },
      { path: "a/:walkSlug", element: <AudioPlayer /> },
      { path: "signup", element: <SignUp /> },
      { path: "login", element: <Login /> },
      { path: "admin/*", element: <AdminRoute /> }
    ]
  }
]);

function App() {
  return (
    <AuthProvider>
      <UserManager />
            <RouterProvider 
              router={router} 
              future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
            />
    </AuthProvider>
  );
}

export default App;