// src/routes/adminRoutes.js
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

// Lazy-Loading für Admin-Komponenten
const AdminMainPage = lazy(() => import('../components/admin/AdminMainPage'));
const UserManagerMain = lazy(() => import('../components/admin/userManager/UserManagerMain'));
const AddUser = lazy(() => import('../components/admin/userManager/AddUser'));
const UserDetails = lazy(() => import('../components/admin/userManager/userDetails/UserDetails'));
const UserDetailsTab = lazy(() => import('../components/admin/userManager/userDetails/UserDetailsTab'));
const LanguageTab = lazy(() => import('../components/admin/languages/LanguageTab'));
const DeletionQueueTab = lazy(() => import('../components/admin/deletionQueue/deletionQueueTab'));
const ContentsTab = lazy(() => import('../components/admin/contents/ContentsTab'));
const CitiesTab = lazy(() => import('../components/admin/cities/CitiesTab'));
const CategoriesTab = lazy(() => import('../components/admin/categories/CategoriesTab'));
const StoriesTab = lazy(() => import('../components/admin/stories/StoriesTab'));
const StoryMetasTab = lazy(() => import('../components/admin/stories/StoryMetasTab'));
const StoryContentsTab = lazy(() => import('../components/admin/stories/StoryContentsTab'));
const StoryDetails = lazy(() => import('../components/admin/stories/StoryDetails'));
const GroupsMetasTab = lazy(() => import('../components/admin/groups/GroupsMetasTab'));
const TrackMetasTab = lazy(() => import('../components/admin/tracks/TrackMetasTab'));
const TrackContentsTab = lazy(() => import('../components/admin/tracks/TrackContentsTab'));
const TrackDetails = lazy(() => import('../components/admin/tracks/TrackDetails'));
const BackupDb = lazy(() => import('../components/admin/backup/BackupDb'));
const QuestMetasTab = lazy(() => import('../components/admin/quests/QuestMetasTab'));
const QuestContentsTab = lazy(() => import('../components/admin/quests/QuestContentsTab'));
const QuestDetails = lazy(() => import('../components/admin/quests/QuestDetails'));
const PuzzleMetasTab = lazy(() => import('../components/admin/puzzles/PuzzleMetasTab'));
const PuzzleContentsTab = lazy(() => import('../components/admin/puzzles/PuzzleContentsTab'));
const PuzzleDetails = lazy(() => import('../components/admin/puzzles/PuzzleDetails'));

// Loading-Komponente für Suspense
const LoadingComponent = () => <div>Komponente wird geladen...</div>;

// Admin-Routen-Komponente
const AdminRoutes = () => (
  <Suspense fallback={<LoadingComponent />}>
    <Routes>
      <Route index element={<AdminMainPage />} />
      <Route path="userManager" element={<UserManagerMain />} />
      <Route path="userManager/addUser" element={<AddUser />} />
      <Route path="userManager/userDetails/:id" element={<UserDetails />} />
      <Route path="userManager/userDetails" element={<UserDetailsTab />} />
      <Route path="languages" element={<LanguageTab />} />
      <Route path="deletionQueue" element={<DeletionQueueTab />} />
      <Route path="contents" element={<ContentsTab />} />
      <Route path="cities" element={<CitiesTab />} />
      <Route path="categories" element={<CategoriesTab />} />
      <Route path="story-metas" element={<StoryMetasTab />} />
      <Route path="stories" element={<StoriesTab />} />
      <Route path="story-contents" element={<StoryContentsTab />} />
      <Route path="stories/:storyId" element={<StoryDetails />} />
      <Route path="group-metas" element={<GroupsMetasTab />} />
      <Route path="track-metas" element={<TrackMetasTab />} />
      <Route path="track-contents" element={<TrackContentsTab />} />
      <Route path="tracks/:trackId" element={<TrackDetails />} />
      <Route path="backup/db" element={<BackupDb />} />
      <Route path="quest-metas" element={<QuestMetasTab />} />
      <Route path="quest-contents" element={<QuestContentsTab />} />
      <Route path="quests/:questId" element={<QuestDetails />} />
      <Route path="puzzle-metas" element={<PuzzleMetasTab />} />
      <Route path="puzzle-contents" element={<PuzzleContentsTab />} />
      <Route path="puzzles/:puzzleId" element={<PuzzleDetails />} />
    </Routes>
  </Suspense>
);

export default AdminRoutes;