import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import '../styles/QrStyles.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const QrReader = ({ tracks, walkSlug, handleTrack, setActiveTab }) => {
  const QrFrame = '/assets/illustrations/qr-frame.svg';
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrVisible, setQrVisible] = useState(true);
  const [scannedResult, setScannedResult] = useState("");
  const [cameraError, setCameraError] = useState('');

  useEffect(() => {
    const checkCameras = async () => {
      try {
        const cameras = await QrScanner.listCameras(true);
        if (cameras.length > 0) {
          initiateScanner(cameras[0].id);
        } else {
          setCameraError('Keine Kamera verfügbar.');
        }
      } catch (error) {
        setCameraError('Zugriff auf die Kamera nicht möglich. Bitte Kameraberechtigungen überprüfen.');
      }
    };

    if (qrVisible) {
      checkCameras();
    } else {
      stopScanner();
    }
  }, [qrVisible]);

  const stopScanner = () => {
    if (scanner.current) {
      scanner.current.stop();
      scanner.current.destroy();
      scanner.current = null;
      setCameraError('');
    }
  };

  const initiateScanner = (cameraId) => {
    if (videoEl.current && qrVisible) {
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl.current || undefined,
      });

      scanner.current.start().catch((error) => {
        console.error('Fehler beim Starten des Scanners:', error);
        setCameraError('Fehler beim Starten des Scanners. Stellen Sie sicher, dass eine Kamera verfügbar und berechtigt ist.');
      });
    }

    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current.destroy();
        scanner.current = null;
      }
    };
  };

  const onScanSuccess = (result) => {
    if (validateResult(result.data)) {
      handleQrCode(result.data);
      scanner.current.stop();
      scanner.current.destroy();
      scanner.current = null;
      setQrVisible(false);
      //setActiveTab();
    } else {
      setScannedResult("QR Code nicht lesbar");
    }
  };

  const onScanFail = (error) => {
    //console.error("Scanning failed:", error);
  };

  useEffect(() => {
    document.body.style.overflow = qrVisible ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [qrVisible]);

  const allowedHosts = ['player.ortspiel.com', 'www.player.ortspiel.com'];

  const validateResult = (data) => {
    try {
      const url = new URL(data);
      return allowedHosts.some(allowedHost => {
        return url.host === allowedHost;
      }) && url.pathname.startsWith('/a/');
    } catch (error) {
      return false;
    }
  };

  const handleQrCode = (data) => {
    const url = new URL(data);
    const pathSegments = url.pathname.split('/').filter(segment => segment.length > 0);
    let walk = null;
    let track = null;

    if (pathSegments.length >= 2) {
      walk = pathSegments[1];
    }
    if (pathSegments.length >= 3) {
      track = pathSegments[2];
    }

    if (walk && track && walk === walkSlug) {
      if (track && isSlugContained(tracks, track)) {
        handleTrack(track);
        setActiveTab('track-details');
      } else {
        handleTrack(null);
      }
    } 
    else if (walk && walk === walkSlug){
      handleTrack(null);
    }
    else if (walk && walk !== walkSlug) {      
      const confirmChangeWalk = window.confirm(`Der QR-Code gehört zu einem anderen Walk (${walk}). Möchten Sie zu diesem Walk wechseln?`);
      if (confirmChangeWalk) {
        window.location.href = data;
      } else {
        handleTrack(null);
      }
    }
  };

  const isSlugContained = (tracks, slug) => {
    return tracks.some(track => track.slug === slug);
  };

  const hideScanner = () => {
    setQrVisible(false);
    stopScanner();
    setActiveTab('track-details');
  };

  return (
    <div>
      {qrVisible && (
        <>
          <div className='title-page'><h2>QR Code Scanner</h2></div>
          <div className='ha-100'></div>
          <div className="qr-overlay">
            <div className="qr-reader">
              {cameraError ? <p>{cameraError}</p> : <>
                <video className="qr-video" ref={videoEl}></video>
                <div ref={qrBoxEl} className="qr-box">
                  <img src={QrFrame} alt="Qr Frame" width="256" height="256" className="qr-frame" />
                </div>
              </>}
              {scannedResult && (
                <p style={{ position: "absolute", top: 20, left: 30, color: "white" }}>
                  {scannedResult}
                </p>
              )}
              <div className='qr-controls '>
                <button onClick={() => hideScanner()} className="qr-close-button" >
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QrReader;
